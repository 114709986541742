.contact {
    background-image: url(../../Assets/contactUs.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.SectionTitle{
    font-family: 'AlegreyaItalic' , sans-serif ;
    font-size: 24px;
    position: absolute;
    z-index: 9999;
    right: 0;
}

.contact::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0%; /* Adjust if needed */
    left: 0;
    background-color: #004A62; /* Ensure the background color is set */
    z-index: 999; /* Ensure it’s on top of other elements */
}

.layer {
    background-color: #002733;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    opacity: 0.7;
}

.textContent {
    position: relative;
    z-index: 99;
    overflow: hidden;
    text-align: center;
    color: white;
}

.textContent h3 {
    font-family: 'AdventSemiBold', sans-serif;
    font-size: 33px;
}

.textContent p {
    font-family: 'MontserratLight', sans-serif;
    font-size: 14px;
}

.formContent {
    position: relative;
    z-index: 99;
    overflow: hidden;
}

.buttonColor {
    background-color: #A6E6F9;
    font-family: "AdventSemiBold", sans-serif;
    font-size: 25px;
    color: #0B0B0B;
    padding: 0 80px;
    transition: background-color 300ms;
}

.buttonColor:hover {
    background-color: white;
}

input::placeholder, 
textarea::placeholder {
    font-family: 'MontserratRegular', sans-serif;
}

form input , form textarea{
    font-family: 'MontserratRegular' , sans-serif;
}

form input:focus, form textarea:focus {
    outline: none;
    border: none;
}