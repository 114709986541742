.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Advent-Pro-Regular';
  src: url('../src/fonts/AdventPro-Regular.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Advent-Pro-medium';
  src: url('../src/fonts/AdventPro-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'AlegreyaItalic';
  src: url('../src//fonts/Alegreya-Italic.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'MontserratLight';
  src: url('../src/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'AdventSemiBold';
  src: url('../src//fonts/AdventPro-SemiBold.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'MontserratRegular';
  src: url('../src//fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 500;
}

