:root {
    --font-size: 70px;
    --font-weight: 500;
    --font-color: white;
}

.Home {
    background-color: #002733;
    height: "100vh";
}

.HomeContent p:nth-of-type(1) {
    font-size: var(--font-size) ;
    color: var(--font-color);
    font-family: "Advent-Pro-Regular" , sans-serif;
  }
  
  .HomeContent p:nth-of-type(2) {
    font-size: var(--font-size) ;
    color: var(--font-color);
    font-family: "AlegreyaItalic" , sans-serif;
  }
  
  .HomeContent p:nth-of-type(3) {
    font-size: 18px ;
    color: var(--font-color);
    font-family: 'MontserratLight' , sans-serif;
}

.bgColor {
    background-color: #A6E6F9;
    font-family: "AdventSemiBold" , sans-serif;
    font-size: 25px;
    color: #0B0B0B;
    padding: 0 80px;
    transition: background-color 300ms;
}

.bgColor:hover {
    background-color: white;
}
