.Services {
    background-color: #002733;
    height: "100vh";
    position: relative;
}

.Services::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0%;
    left: 0;
    background-color: #004A62;
    z-index: 999; 
}

.SectionTitle{
    font-family: 'AlegreyaItalic' , sans-serif ;
    font-size: 24px;
}

.servicesContent h3 {
    font-family: 'AdventSemiBold' , sans-serif;
    font-size: 33px;
}

.servicesContent p {
    font-family: 'MontserratLight' , sans-serif ;
    font-size: 14px;
}
.ServiceCard {
    border: solid 2px #908186;
    border-radius: 8px;
    transition: border 0.2s;
    cursor: pointer;
}

.ServiceCard p:first-of-type {
    font-family: 'MontserratRegular' , sans-serif;
    font-size: 20px;
}

.ServiceCard p:nth-of-type(2) {
    font-family: 'MontserratLight' , sans-serif;
    font-size: 18px;
}

.ServiceCard img {
    transition: transform 0.2s; /* Smooth transition */
  }
  
  .ServiceCard:hover img {
    transform: translate(7px, 7px) scale(1.2) /* Move diagonally on hover */
  }

  .ServiceCard:hover {
    border: solid 2px white;
  }
  


